var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "wrap" }, [
    _c(
      "div",
      { staticClass: "attestation-box" },
      [
        _c("Title", { attrs: { name: "认证信息" } }),
        _c("div", { staticClass: "container" }, [
          _c("div", { staticClass: "content" }, [
            _c(
              "ul",
              { staticClass: "nav" },
              _vm._l(_vm.nav, function (item, index) {
                return _c(
                  "li",
                  {
                    key: item.name,
                    class: { selected: _vm.selectedIndex == index },
                  },
                  [_vm._v(" " + _vm._s(item.name) + " ")]
                )
              }),
              0
            ),
            _c(
              "div",
              { staticClass: "main" },
              [
                _vm.selectedIndex == 0
                  ? _c(
                      "el-form",
                      {
                        key: "ruleForm1",
                        ref: "ruleForm",
                        staticClass: "demo-ruleForm1",
                        attrs: {
                          model: _vm.ruleForm,
                          rules: _vm.rules,
                          "label-width": "120px",
                        },
                      },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: { label: "公司营业执照:", prop: "license" },
                          },
                          [
                            _c(
                              "el-upload",
                              {
                                staticClass: "upload-license",
                                attrs: {
                                  action: _vm.uploadUrl + "?type=0",
                                  "show-file-list": false,
                                  "on-success": _vm.handleLicenseSuccess,
                                  "before-upload": _vm.beforeLicenseUpload,
                                },
                              },
                              [
                                _vm.ruleForm.license
                                  ? _c("img", {
                                      staticClass: "license",
                                      attrs: {
                                        src: _vm.ruleForm.license,
                                        height: "200",
                                        width: "200",
                                      },
                                    })
                                  : _c(
                                      "el-button",
                                      {
                                        staticClass: "licenseBtn",
                                        attrs: {
                                          size: "small",
                                          type: "primary",
                                        },
                                      },
                                      [_vm._v("点击上传")]
                                    ),
                                _c(
                                  "span",
                                  {
                                    staticClass: "el-upload__customtip",
                                    attrs: { slot: "tip" },
                                    slot: "tip",
                                  },
                                  [_vm._v("资料须加盖公章，拍摄照片上传！")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "公司名称:", prop: "name" } },
                          [
                            _c("el-input", {
                              attrs: { placeholder: "请输入公司名称" },
                              model: {
                                value: _vm.ruleForm.name,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "name", $$v)
                                },
                                expression: "ruleForm.name",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "类型:", prop: "type" } },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: { placeholder: "请选择" },
                                model: {
                                  value: _vm.ruleForm.type,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleForm, "type", $$v)
                                  },
                                  expression: "ruleForm.type",
                                },
                              },
                              [
                                _c("el-option", {
                                  attrs: { label: "股份有限公司", value: "1" },
                                }),
                                _c("el-option", {
                                  attrs: { label: "有限责任公司", value: "2" },
                                }),
                                _c("el-option", {
                                  attrs: { label: "合伙企业", value: "3" },
                                }),
                                _c("el-option", {
                                  attrs: { label: "个人独资企业", value: "4" },
                                }),
                                _c("el-option", {
                                  attrs: { label: "其他", value: "5" },
                                }),
                                _c("el-option", {
                                  attrs: { label: "个体工商户", value: "6" },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "详细地址:", prop: "address" } },
                          [
                            _c("el-input", {
                              attrs: { placeholder: "请输入详细地址" },
                              model: {
                                value: _vm.ruleForm.address,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "address", $$v)
                                },
                                expression: "ruleForm.address",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "注册资本:", prop: "capital" } },
                          [
                            _c("el-input", {
                              attrs: { placeholder: "请输入注册资本" },
                              model: {
                                value: _vm.ruleForm.capital,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "capital", $$v)
                                },
                                expression: "ruleForm.capital",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "成立时间:", prop: "date" } },
                          [
                            _c("el-date-picker", {
                              attrs: {
                                format: "yyyy 年 MM 月 dd 日",
                                "value-format": "yyyy-MM-dd",
                                type: "date",
                                placeholder: "选择日期",
                              },
                              model: {
                                value: _vm.ruleForm.date,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "date", $$v)
                                },
                                expression: "ruleForm.date",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "经营范围:", prop: "desc" } },
                          [
                            _c("el-input", {
                              attrs: {
                                type: "textarea",
                                rows: 6,
                                placeholder: "请输入经营范围",
                              },
                              model: {
                                value: _vm.ruleForm.desc,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "desc", $$v)
                                },
                                expression: "ruleForm.desc",
                              },
                            }),
                          ],
                          1
                        ),
                        _c("el-form-item", { attrs: { label: "" } }, [
                          _c(
                            "div",
                            { staticClass: "agreement-box" },
                            [
                              _c("el-checkbox", {
                                on: { change: _vm.changeSelectAgreement },
                                model: {
                                  value: _vm.isSelectAgreement,
                                  callback: function ($$v) {
                                    _vm.isSelectAgreement = $$v
                                  },
                                  expression: "isSelectAgreement",
                                },
                              }),
                              _c("div", { staticClass: "agreement-text" }, [
                                _c("span", [_vm._v("我同意遵守")]),
                                _c(
                                  "span",
                                  {
                                    staticStyle: { color: "#409eff" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.toExplainPage("buyerRules")
                                      },
                                    },
                                  },
                                  [_vm._v("《买家交易规则》")]
                                ),
                                _vm._v("、 "),
                                _c(
                                  "span",
                                  {
                                    staticStyle: { color: "#409eff" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.toExplainPage(
                                          "electronicSignature"
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("《电子签章用户须知》")]
                                ),
                              ]),
                            ],
                            1
                          ),
                        ]),
                        _c(
                          "el-form-item",
                          [
                            this.updateType == "client"
                              ? _c(
                                  "el-button",
                                  {
                                    staticClass: "nextBtn",
                                    attrs: { type: "primary" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.nextForm("ruleForm")
                                      },
                                    },
                                  },
                                  [_vm._v("提交")]
                                )
                              : this.updateType == null
                              ? _c(
                                  "el-button",
                                  {
                                    staticClass: "nextBtn",
                                    attrs: { type: "primary" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.nextForm("ruleForm")
                                      },
                                    },
                                  },
                                  [_vm._v("下一步")]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.selectedIndex == 1
                  ? _c(
                      "el-form",
                      {
                        key: "ruleForm2",
                        ref: "ruleForm2",
                        staticClass: "demo-ruleForm2",
                        attrs: {
                          model: _vm.ruleForm2,
                          rules: _vm.rules2,
                          "label-width": "160px",
                        },
                      },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: { label: "上传法人证件信息:", required: "" },
                          },
                          [
                            _c(
                              "el-row",
                              { attrs: { gutter: 2 } },
                              [
                                _c(
                                  "el-col",
                                  { attrs: { span: 11 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { prop: "photo1" } },
                                      [
                                        _c(
                                          "el-upload",
                                          {
                                            staticClass: "upload-photo",
                                            attrs: {
                                              action: _vm.uploadUrl + "?type=1",
                                              "show-file-list": false,
                                              "on-success":
                                                _vm.handlePhotoSuccess1,
                                              "before-upload":
                                                _vm.beforeLicenseUpload,
                                            },
                                          },
                                          [
                                            _vm.ruleForm2.photo1
                                              ? _c("img", {
                                                  staticClass: "photo-img",
                                                  attrs: {
                                                    src: _vm.ruleForm2.photo1,
                                                    height: "200",
                                                    width: "200",
                                                  },
                                                })
                                              : _c(
                                                  "div",
                                                  { staticClass: "photo" },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "el-icon-circle-plus",
                                                    }),
                                                    _c("p", [
                                                      _vm._v("人像面照片"),
                                                    ]),
                                                  ]
                                                ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  { attrs: { span: 11 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { prop: "photo2" } },
                                      [
                                        _c(
                                          "el-upload",
                                          {
                                            staticClass: "upload-photo",
                                            attrs: {
                                              action: _vm.uploadUrl + "?type=2",
                                              "show-file-list": false,
                                              "on-success":
                                                _vm.handlePhotoSuccess2,
                                              "before-upload":
                                                _vm.beforeLicenseUpload,
                                            },
                                          },
                                          [
                                            _vm.ruleForm2.photo2
                                              ? _c("img", {
                                                  staticClass: "photo-img",
                                                  attrs: {
                                                    src: _vm.ruleForm2.photo2,
                                                    height: "200",
                                                    width: "200",
                                                  },
                                                })
                                              : _c(
                                                  "div",
                                                  { staticClass: "photo" },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "el-icon-circle-plus",
                                                    }),
                                                    _c("p", [
                                                      _vm._v("国徽面照片"),
                                                    ]),
                                                  ]
                                                ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "法人姓名:", prop: "name" } },
                          [
                            _c("el-input", {
                              attrs: { placeholder: "请输入法人姓名" },
                              model: {
                                value: _vm.ruleForm2.name,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm2, "name", $$v)
                                },
                                expression: "ruleForm2.name",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: { label: "法人身份证号码:", prop: "number" },
                          },
                          [
                            _c("el-input", {
                              attrs: { placeholder: "请输入身份证号码" },
                              model: {
                                value: _vm.ruleForm2.number,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm2, "number", $$v)
                                },
                                expression: "ruleForm2.number",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "证件签发机关:",
                              prop: "mechanism",
                            },
                          },
                          [
                            _c("el-input", {
                              attrs: { placeholder: "请输入证件签发机关" },
                              model: {
                                value: _vm.ruleForm2.mechanism,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm2, "mechanism", $$v)
                                },
                                expression: "ruleForm2.mechanism",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "证件签发日期:", prop: "date1" } },
                          [
                            _c("el-date-picker", {
                              attrs: {
                                "picker-options": _vm.startDatePicker,
                                format: "yyyy 年 MM 月 dd 日",
                                "value-format": "yyyy-MM-dd",
                                type: "date",
                                placeholder: "选择日期",
                              },
                              model: {
                                value: _vm.ruleForm2.date1,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm2, "date1", $$v)
                                },
                                expression: "ruleForm2.date1",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "证件失效日期:", prop: "date2" } },
                          [
                            _c("el-date-picker", {
                              attrs: {
                                "picker-options": _vm.endDatePicker,
                                format: "yyyy 年 MM 月 dd 日",
                                "value-format": "yyyy-MM-dd",
                                type: "date",
                                placeholder: "选择日期",
                              },
                              model: {
                                value: _vm.ruleForm2.date2,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm2, "date2", $$v)
                                },
                                expression: "ruleForm2.date2",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          [
                            this.updateType == null
                              ? _c(
                                  "el-button",
                                  {
                                    staticClass: "prevBtn",
                                    attrs: { plain: "" },
                                    on: { click: _vm.prevForm },
                                  },
                                  [_vm._v("上一步")]
                                )
                              : _vm._e(),
                            this.updateType == null
                              ? _c(
                                  "el-button",
                                  {
                                    staticClass: "nextBtn",
                                    attrs: { type: "primary" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.submitForm("ruleForm2")
                                      },
                                    },
                                  },
                                  [_vm._v("下一步")]
                                )
                              : _vm._e(),
                            this.updateType == "corUser"
                              ? _c(
                                  "el-button",
                                  {
                                    staticClass: "submint",
                                    attrs: { type: "primary" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.submitForm("ruleForm2")
                                      },
                                    },
                                  },
                                  [_vm._v("提交")]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.selectedIndex == 2
                  ? _c(
                      "el-form",
                      {
                        key: "ruleForm3",
                        ref: "ruleForm3",
                        staticClass: "demo-ruleForm2",
                        attrs: {
                          model: _vm.ruleForm3,
                          rules: _vm.rules3,
                          "label-width": "160px",
                        },
                      },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "授权书:", required: "" } },
                          [
                            _c(
                              "el-row",
                              { attrs: { gutter: 2 } },
                              [
                                _c(
                                  "el-col",
                                  { attrs: { span: 11 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { prop: "authorCompanyFile" } },
                                      [
                                        _c(
                                          "el-upload",
                                          {
                                            staticClass: "upload-photo",
                                            attrs: {
                                              action: _vm.uploadPath,
                                              "show-file-list": false,
                                              "on-success":
                                                _vm.handlePhotoSuccess4,
                                              "before-upload":
                                                _vm.beforeLicenseUpload2,
                                            },
                                          },
                                          [
                                            _vm.ruleForm3.authorCompanyFile
                                              ? _c("img", {
                                                  staticClass: "photo-img",
                                                  attrs: {
                                                    src: _vm.ruleForm3
                                                      .authorCompanyFile,
                                                    height: "200",
                                                    width: "200",
                                                  },
                                                })
                                              : _c(
                                                  "div",
                                                  { staticClass: "photo" },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "el-icon-circle-plus",
                                                    }),
                                                    _c("p", [
                                                      _vm._v("授权委托书文件"),
                                                    ]),
                                                  ]
                                                ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "模板下载:" } },
                          [
                            _c("el-link", { attrs: { type: "primary" } }, [
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href: "docx/公司授权委托书.doc",
                                    target: "_blank",
                                    download: "",
                                  },
                                },
                                [_vm._v("授权委托书文件")]
                              ),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "纳税人资格证书:", required: "" } },
                          [
                            _c(
                              "el-row",
                              { attrs: { gutter: 2 } },
                              [
                                _c(
                                  "el-col",
                                  { attrs: { span: 11 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { prop: "authorCompanyFile" } },
                                      [
                                        _c(
                                          "el-upload",
                                          {
                                            staticClass: "upload-photo",
                                            attrs: {
                                              action: _vm.uploadPath,
                                              "show-file-list": false,
                                              "on-success":
                                                _vm.handleTaxpayerSuccess,
                                              "before-upload":
                                                _vm.beforeTaxpayerUpload2,
                                            },
                                          },
                                          [
                                            _vm.ruleForm3.taxpayerCertificate
                                              ? _c("img", {
                                                  staticClass: "photo-img",
                                                  attrs: {
                                                    src: _vm.ruleForm3
                                                      .taxpayerCertificate,
                                                    height: "200",
                                                    width: "200",
                                                  },
                                                })
                                              : _c(
                                                  "div",
                                                  { staticClass: "photo" },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "el-icon-circle-plus",
                                                    }),
                                                    _c("p", [
                                                      _vm._v("纳税人资格证书"),
                                                    ]),
                                                  ]
                                                ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          [
                            this.updateType == null
                              ? _c(
                                  "el-button",
                                  {
                                    staticClass: "prevBtn",
                                    attrs: { plain: "" },
                                    on: { click: _vm.three },
                                  },
                                  [_vm._v("上一步")]
                                )
                              : _vm._e(),
                            _c(
                              "el-button",
                              {
                                staticClass: "submint",
                                attrs: { type: "primary" },
                                on: {
                                  click: function ($event) {
                                    return _vm.threeForm("ruleForm3")
                                  },
                                },
                              },
                              [_vm._v("提交")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.selectedIndex == 3
                  ? _c(
                      "div",
                      { staticClass: "submit-state" },
                      [
                        _c("submit-state", {
                          attrs: { data: _vm.submitState },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }