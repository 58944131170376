var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "state-box" }, [
    _vm.data.state === "pending"
      ? _c("div", { staticClass: "state-pending" }, [
          _vm._m(0),
          _c("p", { staticClass: "state-msg" }, [
            _vm._v(_vm._s(_vm.data.message)),
          ]),
        ])
      : _vm._e(),
    _vm.data.state === "success"
      ? _c("div", { staticClass: "state-pending" }, [
          _vm._m(1),
          _c("p", { staticClass: "state-msg" }, [
            _vm._v(_vm._s(_vm.data.message)),
          ]),
          _vm._m(2),
        ])
      : _vm._e(),
    _vm.data.state === "error"
      ? _c(
          "div",
          { staticClass: "state-pending" },
          [
            _vm._m(3),
            _c("p", { staticClass: "state-msg" }, [
              _vm._v(_vm._s(_vm.data.message)),
            ]),
            _c(
              "el-button",
              {
                attrs: { type: "primary" },
                on: {
                  click: function ($event) {
                    return _vm.updateApprvStatus(_vm.data.clientInfoId)
                  },
                },
              },
              [_vm._v("点击重新申请")]
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "state-icon" }, [
      _c("img", {
        staticClass: "icon",
        attrs: { src: require("../image/icon-wait.png") },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "state-icon" }, [
      _c("i", { staticClass: "el-icon-success" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "state-desc" }, [
      _c("span", [_vm._v("有效期：")]),
      _vm._v("2021-03-15 至 2022-03-14"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "state-icon" }, [
      _c("i", { staticClass: "el-icon-error" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }