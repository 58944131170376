<template>
    <div class="state-box">
        <div class="state-pending" v-if="data.state === 'pending'">
            <div class="state-icon">
                <img class="icon" src="../image/icon-wait.png" />
            </div>
            <p class="state-msg">{{data.message}}</p>
        </div>
        <div class="state-pending" v-if="data.state === 'success'">
            <div class="state-icon">
                <i class="el-icon-success"></i>
            </div>
            <p class="state-msg">{{data.message}}</p>
            <p class="state-desc"><span>有效期：</span>2021-03-15 至 2022-03-14</p>
        </div>
        <div class="state-pending" v-if="data.state === 'error'">
            <div class="state-icon">
                <i class="el-icon-error"></i>
            </div>
            <p class="state-msg">{{data.message}}</p>
                   <el-button type="primary"  @click="updateApprvStatus(data.clientInfoId)">点击重新申请</el-button>
        </div>
    </div>
</template>

<script>
import  {updateApprvStatus } from '../api'
    export default {
        name: 'SubmitState',
        props: ['data'],
        data () {
            return {


            }
        },
        methods: {
            updateApprvStatus(clientInfoId){
                 this.$parent.updateApprvStatus(clientInfoId);
            }


          }
}


</script>

<style lang="less" scoped>
    .state-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;

        .state-icon {
            text-align: center;
            font-size: 50px;

            .el-icon-pending {
                color: #F7B81D;
            }
            .el-icon-success {
                color: #55C176;
            }
            .el-icon-error {
                color: red;
            }
            .icon {
                width: 48px;
                height: 48px;
            }
        }
        .state-msg {
            font-size: 16px;
            margin-top: 16px;
        }
        .state-desc {
            font-size: 14px;
            margin-top: 8px;

            span {
                color: #999;
            }
        }
    }
</style>