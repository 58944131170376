<template>
  <div class="wrap">
    <!-- 认证信息 -->
    <div class="attestation-box">
      <Title name="认证信息"></Title>
      <div class="container">
        <div class="content">
          <ul class="nav">
            <li v-for="(item, index) in nav" :key="item.name" :class="{selected: selectedIndex == index}">
              {{ item.name }}
            </li>
          </ul>

          <div class="main">
            <!-- 企业信息 -->
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px" class="demo-ruleForm1" v-if="selectedIndex == 0" key="ruleForm1">
              <el-form-item label="公司营业执照:" prop="license">
                <el-upload class="upload-license" :action="uploadUrl + '?type=0'" :show-file-list="false" :on-success="handleLicenseSuccess" :before-upload="beforeLicenseUpload">
                  <img v-if="ruleForm.license" :src="ruleForm.license" class="license" height="200" width="200" />
                  <el-button v-else size="small" type="primary" class="licenseBtn">点击上传</el-button>
                  <span slot="tip" class="el-upload__customtip">资料须加盖公章，拍摄照片上传！</span>
                </el-upload>
              </el-form-item>
              <el-form-item label="公司名称:" prop="name">
                <el-input v-model="ruleForm.name" placeholder="请输入公司名称"></el-input>
              </el-form-item>
              <el-form-item label="类型:" prop="type">
                <el-select v-model="ruleForm.type" placeholder="请选择">
                  <el-option label="股份有限公司" value="1"></el-option>
                  <el-option label="有限责任公司" value="2"></el-option>
                  <el-option label="合伙企业" value="3"></el-option>
                  <el-option label="个人独资企业" value="4"></el-option>
                  <el-option label="其他" value="5"></el-option>
                  <el-option label="个体工商户" value="6"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="详细地址:" prop="address">
                <el-input v-model="ruleForm.address" placeholder="请输入详细地址"></el-input>
              </el-form-item>
              <el-form-item label="注册资本:" prop="capital">
                <el-input v-model="ruleForm.capital" placeholder="请输入注册资本"></el-input>
              </el-form-item>
              <el-form-item label="成立时间:" prop="date">
                <el-date-picker v-model="ruleForm.date" format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd" type="date" placeholder="选择日期"> </el-date-picker>
              </el-form-item>
              <el-form-item label="经营范围:" prop="desc">
                <el-input type="textarea" :rows="6" v-model="ruleForm.desc" placeholder="请输入经营范围"></el-input>
              </el-form-item>
              <el-form-item label="">
                <div class="agreement-box">
                  <el-checkbox v-model="isSelectAgreement" @change="changeSelectAgreement"></el-checkbox>
                  <div class="agreement-text">
                    <span>我同意遵守</span>
                    <span @click="toExplainPage('buyerRules')" style="color:#409eff">《买家交易规则》</span>、
                    <span @click="toExplainPage('electronicSignature')" style="color:#409eff">《电子签章用户须知》</span>
                  </div>
                </div>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" v-if="this.updateType == 'client'" @click="nextForm('ruleForm')" class="nextBtn">提交</el-button>
                <el-button type="primary" v-else-if="this.updateType == null" @click="nextForm('ruleForm')" class="nextBtn">下一步</el-button>
              </el-form-item>
            </el-form>

            <!-- 法人信息 -->
            <el-form :model="ruleForm2" :rules="rules2" ref="ruleForm2" label-width="160px" class="demo-ruleForm2" v-if="selectedIndex == 1" key="ruleForm2">
              <el-form-item label="上传法人证件信息:" required>
                <el-row :gutter="2">
                  <el-col :span="11">
                    <el-form-item prop="photo1">
                      <el-upload class="upload-photo" :action="uploadUrl + '?type=1'" :show-file-list="false" :on-success="handlePhotoSuccess1" :before-upload="beforeLicenseUpload">
                        <img v-if="ruleForm2.photo1" :src="ruleForm2.photo1" class="photo-img" height="200" width="200" />
                        <div class="photo" v-else>
                          <i class="el-icon-circle-plus"></i>
                          <p>人像面照片</p>
                        </div>
                      </el-upload>
                    </el-form-item>
                  </el-col>
                  <el-col :span="11">
                    <el-form-item prop="photo2">
                      <el-upload class="upload-photo" :action="uploadUrl + '?type=2'" :show-file-list="false" :on-success="handlePhotoSuccess2" :before-upload="beforeLicenseUpload">
                        <img v-if="ruleForm2.photo2" :src="ruleForm2.photo2" class="photo-img" height="200" width="200" />
                        <div class="photo" v-else>
                          <i class="el-icon-circle-plus"></i>
                          <p>国徽面照片</p>
                        </div>
                      </el-upload>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-form-item>
              <el-form-item label="法人姓名:" prop="name">
                <el-input v-model="ruleForm2.name" placeholder="请输入法人姓名"></el-input>
              </el-form-item>
              <el-form-item label="法人身份证号码:" prop="number">
                <el-input v-model="ruleForm2.number" placeholder="请输入身份证号码"></el-input>
              </el-form-item>
              <el-form-item label="证件签发机关:" prop="mechanism">
                <el-input v-model="ruleForm2.mechanism" placeholder="请输入证件签发机关"></el-input>
              </el-form-item>
              <el-form-item label="证件签发日期:" prop="date1">
                <el-date-picker v-model="ruleForm2.date1" :picker-options="startDatePicker" format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd" type="date" placeholder="选择日期"> </el-date-picker>
              </el-form-item>
              <el-form-item label="证件失效日期:" prop="date2">
                <el-date-picker v-model="ruleForm2.date2" :picker-options="endDatePicker" format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd" type="date" placeholder="选择日期"> </el-date-picker>
              </el-form-item>
              <el-form-item>
                <el-button plain v-if="this.updateType == null" @click="prevForm" class="prevBtn">上一步</el-button>
                <el-button type="primary" v-if="this.updateType == null" @click="submitForm('ruleForm2')" class="nextBtn">下一步</el-button>
                <el-button v-if="this.updateType == 'corUser'" type="primary" @click="submitForm('ruleForm2')" class="submint">提交</el-button>
              </el-form-item>
            </el-form>

            <!-- 授权书 -->
            <el-form :model="ruleForm3" :rules="rules3" ref="ruleForm3" label-width="160px" class="demo-ruleForm2" v-if="selectedIndex == 2" key="ruleForm3">
              <el-form-item label="授权书:" required>
                <el-row :gutter="2">
                  <!--                  <el-col  :span="11">-->
                  <!--                    <el-form-item prop="authorPersonFile">-->
                  <!--                      <el-upload-->
                  <!--                          class="upload-photo"-->
                  <!--                          :action="uploadPath"-->
                  <!--                          :show-file-list="false"-->
                  <!--                          :on-success="handlePhotoSuccess3"-->
                  <!--                          :before-upload="beforeLicenseUpload2">-->
                  <!--                        <img v-if="ruleForm3.authorPersonFile" :src="ruleForm3.authorPersonFile" class="photo-img" height="200" width="200">-->
                  <!--                        <div class="photo" v-else>-->
                  <!--                          <i class="el-icon-circle-plus"></i>-->
                  <!--                          <p>个人信息查询授权书</p>-->
                  <!--                        </div>-->
                  <!--                      </el-upload>-->
                  <!--                    </el-form-item>-->
                  <!--                  </el-col>-->
                  <el-col :span="11">
                    <el-form-item prop="authorCompanyFile">
                      <el-upload class="upload-photo" :action="uploadPath" :show-file-list="false" :on-success="handlePhotoSuccess4" :before-upload="beforeLicenseUpload2">
                        <img v-if="ruleForm3.authorCompanyFile" :src="ruleForm3.authorCompanyFile" class="photo-img" height="200" width="200" />
                        <div class="photo" v-else>
                          <i class="el-icon-circle-plus"></i>
                          <p>授权委托书文件</p>
                        </div>
                      </el-upload>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-form-item>
              <el-form-item label="模板下载:">
                <!--                <el-link type="primary"> <a href="docx/公司个人信息查询授权书.docx" target="_blank" download >个人信息查询授权书</a></el-link>-->
                <el-link type="primary"> <a href="docx/公司授权委托书.doc" target="_blank" download>授权委托书文件</a></el-link>
              </el-form-item>
              <!--              <el-form-item>-->
              <!--                <el-checkbox v-model="ruleForm3.checked">同意签订(认证企业授权承诺书)</el-checkbox>-->
              <!--              </el-form-item>-->

              <el-form-item label="纳税人资格证书:" required>
                <el-row :gutter="2">
                  <el-col :span="11">
                    <el-form-item prop="authorCompanyFile">
                      <el-upload class="upload-photo" :action="uploadPath" :show-file-list="false" :on-success="handleTaxpayerSuccess" :before-upload="beforeTaxpayerUpload2">
                        <img v-if="ruleForm3.taxpayerCertificate" :src="ruleForm3.taxpayerCertificate" class="photo-img" height="200" width="200" />
                        <div class="photo" v-else>
                          <i class="el-icon-circle-plus"></i>
                          <p>纳税人资格证书</p>
                        </div>
                      </el-upload>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-form-item>
              <el-form-item>
                <el-button plain v-if="this.updateType == null" @click="three" class="prevBtn">上一步</el-button>
                <el-button type="primary" @click="threeForm('ruleForm3')" class="submint">提交</el-button>
              </el-form-item>
            </el-form>

            <div class="submit-state" v-if="selectedIndex == 3">
              <submit-state :data="submitState"></submit-state>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Title from '../component/Title';
import submitState from '../component/SubmitState';

//4.vue页面引入
import {findByClientInfoId, updateApprvStatus, updateTClientInfoOne, uploadPath, updateTClientInfoThree} from '../api';
import {updateTClientInfoTwo} from '../api';
import {API_PREFIX} from '@/api/config';

export default {
  created() {
    this.initCilent();
  },
  components: {Title, submitState},
  computed: {},
  data() {
    let validateNumber = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('身份证不能为空'));
      }
      if (!/^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$|^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/.test(value)) {
        return callback(new Error('请输入正确的身份证号码'));
      }
      return callback();
    };
    return {
      uploadUrl: API_PREFIX + '/wx/clientInfo/check',
      updateType: undefined,
      uploadPath,
      startDatePicker: this.beginDate(),
      endDatePicker: this.processDate(),
      nav: [
        {
          name: '1.企业信息'
        },
        {
          name: '2.法人信息  '
        },
        {
          name: '3.上传授权'
        },
        {
          name: '4.提交认证'
        }
      ],
      selectedIndex: 0,
      ruleForm: {
        license: undefined,
        socialCreditCode: undefined,
        name: undefined,
        type: undefined,
        region: undefined,
        province: undefined,
        city: undefined,
        address: undefined,
        capital: undefined,
        date: undefined,
        desc: undefined,
        clientInfoId: undefined
      },
      rules: {
        license: [{required: true, message: '请上传公司营业执照', trigger: 'change'}],
        name: [{required: true, message: '请输入公司名称', trigger: 'blur'}],
        type: [{required: true, message: '请选择类型', trigger: 'change'}],
        // province: [
        //   { required: true, message: '请选择省份', trigger: 'change' }
        // ],
        // city: [
        //   { required: true, message: '请选择城市', trigger: 'change' }
        // ],
        address: [{required: true, message: '请输入详细地址', trigger: 'blur'}],
        capital: [{required: true, message: '请输入注册资本', trigger: 'blur'}],
        date: [{required: true, message: '请选择成立时间', trigger: 'change'}],
        desc: [{required: true, message: '请填写经营范围', trigger: 'blur'}]
      },
      ruleForm2: {
        photo1: undefined,
        photo2: undefined,
        name: undefined,
        number: undefined,
        mechanism: undefined,
        date1: undefined,
        date2: undefined
      },
      rules2: {
        photo1: [{required: true, message: '请上传法人证件信息', trigger: 'change'}],
        photo2: [{required: true, message: '请上传法人证件信息', trigger: 'change'}],
        name: [{required: true, message: '请输入法人姓名', trigger: 'blur'}],
        number: [{validator: validateNumber, required: true, trigger: 'blur'}],
        mechanism: [{required: true, message: '请输入证件签发机关', trigger: 'blur'}],
        date1: [{required: true, message: '请选择证件签发日期', trigger: 'change'}],
        date2: [{required: true, message: '请选择证件失效日期', trigger: 'change'}]
      },
      ruleForm3: {
        //  authorPersonFile:'',
        authorCompanyFile: '',
        clientInfoId: '',
        taxpayerCertificate: ''
      },
      rules3: {
        // authorPersonFile: [
        //   { required: true, message: '请上传公司个人信息查询授权书', trigger: 'change' }
        // ],
        authorCompanyFile: [{required: true, message: '请上传公司授权委托书', trigger: 'change'}],
        taxpayerCertificate: [{required: true, message: '请上传纳税人资格证书', trigger: 'change'}]
      },
      submitState: {
        state: 'success',
        message: '已认证',
        clientInfoId: ''
      },
      isSelectAgreement: false
    };
  },
  mounted() {
    this.isSelectAgreement = this.$store.getters['getterBuyerRules'] && this.$store.getters['getterElectronicSignature'];
  },
  methods: {
    typeMethod(type) {
      if (type.indexOf('股份有限公司') != -1) {
        return 1;
      }
      if (type.indexOf('有限责任公司') != -1) {
        return 2;
      }
      if (type.indexOf('合伙企业') != -1) {
        return 3;
      }
      if (type.indexOf('个人独资企业') != -1) {
        return 4;
      }
      if (type.indexOf('其他') != -1) {
        return 5;
      }
      if (type.indexOf('个体工商户') != -1) {
        return 6;
      }
    },
    initCilent() {
      //是修改公司还是修改企业
      this.updateType = this.$route.query.updateType;
      //加载审核信息
      findByClientInfoId().then(res => {
        if (res.errno == 0) {
          //跳转到审核中
          if (res.data.apprvStatus == 'A') {
            this.selectedIndex = 3;
            this.submitState = {
              state: 'pending',
              message: '认证资料已提交，请联系客服并现场提供盖章的授权委托书原件!'
            };
            //跳转到审核通过
          } else if (res.data.apprvStatus == 'P') {
            //如果是修改企业
            if (this.updateType == 'client') {
              this.selectedIndex = 0;
              this.saveClientInfo(res);
              //如果是修改法人
            } else if (this.updateType == 'corUser') {
              this.selectedIndex = 1;
              this.saveClientInfo(res);
            } else {
              this.selectedIndex = 3;
              this.submitState = {
                state: 'success',
                message: '已审核！'
              };
            }
            //已拒绝
          } else if (res.data.apprvStatus == 'F') {
            this.selectedIndex = 3;
            this.submitState = {
              state: 'error',
              message: '已拒绝！',
              clientInfoId: res.data.clientInfoId
            };
            //跳转到开始页面
          } else {
            if (res.data != null) {
              this.saveClientInfo(res);
            }
            this.selectedIndex = this.$route.query.state || 0;
          }
        }
      });
    },
    //封装返回的数据
    saveClientInfo(res) {
      if (sessionStorage.getItem('enterpriseInfo')) {
        this.ruleForm = JSON.parse(sessionStorage.getItem('enterpriseInfo'));
        sessionStorage.removeItem('enterpriseInfo');
      } else {
        this.ruleForm.clientInfoId = res.data.clientInfoId;
        this.ruleForm.socialCreditCode = res.data.socialCreditCode;
        this.ruleForm.license = res.data.businessLicenseImages; //营业执照
        this.ruleForm.name = res.data.clientName; //公司名称
        this.ruleForm.type = res.data.enterpiseType; //公司类型
        this.ruleForm.address = res.data.offAddr; //详细地址
        this.ruleForm.capital = res.data.regCapital; //注册资本
        this.ruleForm.date = res.data.regDay; //成立时间
        this.ruleForm.desc = res.data.busScope; //经营范围
        this.ruleForm.clientInfoId = res.data.clientInfoId; //用户id
      }
      this.ruleForm2.photo1 = res.data.identityCardPositiveImages;
      this.ruleForm2.photo2 = res.data.identityCardBackImages;
      this.ruleForm2.name = res.data.corName;
      this.ruleForm2.number = res.data.idCardNo;
      this.ruleForm2.mechanism = res.data.organization;
      this.ruleForm2.date1 = res.data.expiryStartDate;
      this.ruleForm2.date2 = res.data.expiryEndtDate;
      this.ruleForm3.authorCompanyFile = res.data.authorCompanyFile;
      this.ruleForm3.authorPersonFile = res.data.authorPersonFile;
      this.ruleForm3.taxpayerCertificate = res.data.taxpayerCertificate;
    },
    // 上传凭证之前
    beforeLicenseUpload(file) {
      const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG/PNG 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!');
      }
      return isJPG && isLt2M;
    },
    // 上传凭证成功
    handleLicenseSuccess(res, file) {
      console.log(res);
      if (res.errno == 0) {
        this.ruleForm.license = res.data.url;
        this.ruleForm.name = res.data.data.words_result.单位名称.words;
        this.ruleForm.date = res.data.data.words_result.成立日期.words
          .replace('年', '-')
          .replace('月', '-')
          .replace('日', '');
        this.ruleForm.desc = res.data.data.words_result.经营范围.words;
        this.ruleForm.address = res.data.data.words_result.地址.words;
        this.ruleForm.socialCreditCode = res.data.data.words_result.社会信用代码.words;
        this.ruleForm.type = res.data.data.words_result.类型.words;
        this.ruleForm.capital = res.data.data.words_result.注册资本.words;
        this.$notify.success({
          title: '成功',
          message: '上传成功'
        });
      } else {
        this.$notify.error({
          title: '失败',
          message: res.errmsg
        });
      }
    },

    // 上传凭证之前
    beforeLicenseUpload2(file) {
      const isJPG = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'application/pdf';
      const isLt10M = file.size / 1024 / 1024 < 10;

      if (!isJPG) {
        this.$message.error('上传文件只能是 JPG/PNG/PDF 格式!');
      }
      if (!isLt10M) {
        this.$message.error('上传文件大小不能超过 10MB!');
      }
      return isJPG && isLt10M;
    },
    //纳税人资格证书
    beforeTaxpayerUpload2(file) {
      const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
      const isLt10M = file.size / 1024 / 1024 < 10;
      if (!isJPG) {
        this.$message.error('上传文件只能是 JPG/PNG 格式!');
      }
      if (!isLt10M) {
        this.$message.error('上传文件大小不能超过 10MB!');
      }
      return isJPG && isLt10M;
    },

    updateApprvStatus(clientInfoId) {
      updateApprvStatus({clientInfoId: clientInfoId}).then(res => {
        this.$notify.success({
          title: '成功',
          message: '操作成功'
        });
        this.saveClientInfo(res);
        this.selectedIndex = 0;
      });
    },
    // 上传法人证件信息成功1
    handlePhotoSuccess1(res, file) {
      if (res.errno == 0) {
        this.ruleForm2.photo1 = res.data.url;
        this.ruleForm2.name = res.data.data.words_result.姓名.words;
        this.ruleForm2.number = res.data.data.words_result.公民身份号码.words;
        this.$notify.success({
          title: '成功',
          message: '上传成功'
        });
      } else {
        this.$notify.error({
          title: '失败',
          message: res.errmsg
        });
      }
    },
    beginDate() {
      const self = this;
      return {
        disabledDate(time) {
          if (self.ruleForm2.date2) {
            //如果结束时间不为空，则小于结束时间
            return new Date(self.ruleForm2.date2).getTime() < time.getTime();
          } else {
            // return time.getTime() > Date.now()//开始时间不选时，结束时间最大值小于等于当天
          }
        }
      };
    },
    processDate() {
      const self = this;
      return {
        disabledDate(time) {
          if (self.ruleForm2.date1) {
            //如果开始时间不为空，则结束时间大于开始时间
            return new Date(self.ruleForm2.date1).getTime() > time.getTime();
          } else {
            // return time.getTime() > Date.now()//开始时间不选时，结束时间最大值小于等于当天
          }
        }
      };
    },
    // 上传法人证件信息成功2
    handlePhotoSuccess2(res, file) {
      if (res.errno == 0) {
        this.ruleForm2.photo2 = res.data.url;
        this.ruleForm2.mechanism = res.data.data.words_result.签发机关.words;
        this.ruleForm2.date1 = res.data.data.words_result.签发日期.words
          .match(/(\d{4})(\d{2})(\d{2})/)
          .filter((item, index) => index > 0)
          .join('-');

        if (res.data.data.words_result.失效日期.words.match(/(\d{4})(\d{2})(\d{2})/)) {
          this.ruleForm2.date2 = res.data.data.words_result.失效日期.words
            .match(/(\d{4})(\d{2})(\d{2})/)
            .filter((item, index) => index > 0)
            .join('-');
        } else {
          this.ruleForm2.date2 = '长期';
        }

        this.$notify.success({
          title: '成功',
          message: '上传成功'
        });
      } else {
        this.$notify.error({
          title: '失败',
          message: res.errmsg
        });
      }
    },
    // 企业信息--提交表单
    nextForm(formName) {
      var _this = this;
      _this.$refs[formName].validate(valid => {
        if (valid) {
          //  alert('submit!');
          // _this.$router.push({
          //   path: '/wx/clientInfo/updateTClientInfoOne'
          // });
          //回调函数
          //   if(_this.ruleForm.socialCreditCode==null){
          //       _this.ruleForm.type=_this.typeMethod(_this.ruleForm.type)
          //   }

          if (!_this.isSelectAgreement) return this.$message.warning('请勾选遵守买家交易规则和电子签章用户须知！');

          updateTClientInfoOne({
            clientInfoId: _this.ruleForm.clientInfoId,
            businessLicenseImages: _this.ruleForm.license, //营业执照
            clientName: _this.ruleForm.name, //公司名称
            enterpiseType: isNaN(_this.ruleForm.type) ? this.typeMethod(_this.ruleForm.type) : _this.ruleForm.type, //公司类型
            offAddr: _this.ruleForm.address, //详细地址
            regCapital: _this.ruleForm.capital, //注册资本
            regDay: _this.ruleForm.date, //成立时间
            busScope: _this.ruleForm.desc,
            province: _this.ruleForm.province,
            city: _this.ruleForm.city,
            socialCreditCode: _this.ruleForm.socialCreditCode,
            apprvStatus: this.updateType
          }).then(res => {
            this.ruleForm.clientInfoId = res.data;
            if (res.errno == 0) {
              if (res.data != null) {
              }
              this.$notify.success({
                title: '成功',
                message: '操作成功'
              });
              if (this.updateType == 'client') {
                this.selectedIndex = 3;
                this.submitState = {
                  state: 'pending',
                  message: '认证资料已提交，请联系客服并现场提供盖章的授权委托书原件!'
                };
              } else {
                _this.selectedIndex = 1;
              }
            } else {
              this.$notify.error({
                title: '失败',
                message: res.errmsg
              });
            }
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    // 法人信息--返回上一页
    prevForm() {
      this.selectedIndex = 0;
    },
    // 法人信息--提交表单
    submitForm(formName) {
      var _this = this;
      _this.$refs[formName].validate(valid => {
        if (valid) {
          //     if(_this.ruleForm2.checked) {
          updateTClientInfoTwo({
            identityCardPositiveImages: this.ruleForm2.photo1,
            identityCardBackImages: this.ruleForm2.photo2,
            corName: _this.ruleForm2.name, //法人姓名
            idCardNo: _this.ruleForm2.number, //法人身份证号码
            organization: _this.ruleForm2.mechanism, //证件签发机关(法人)
            expiryStartDate: _this.ruleForm2.date1, //开始
            expiryEndtDate: _this.ruleForm2.date2, //过期
            clientInfoId: _this.ruleForm.clientInfoId,
            apprvStatus: this.updateType
          }).then(res => {
            if (res.errno == 0) {
              this.$notify.success({
                title: '成功',
                message: '操作成功'
              });
              if (this.updateType == 'corUser') {
                this.selectedIndex = 3;
                this.submitState = {
                  state: 'pending',
                  message: '认证资料已提交，请联系客服并现场提供盖章的授权委托书原件!'
                };
              } else {
                _this.selectedIndex = 2;
              }
            } else {
              this.$notify.error({
                title: '失败',
                message: res.errmsg
              });
            }
          });
          // _this.submitState = {
          //   state: 'pending',
          //   message: '认证资料已提交，请联系客服并现场提供盖章的授权委托书原件!'
          // };
          //     } else {
          //    _this.$message.error('请同意签订认证企业授权承诺书');
          //     }
        } else {
          // console.log('error submit!!');
          // return false;
        }
      });
    },
    //申请认证 上一步
    three() {
      this.selectedIndex = 1;
    },
    handlePhotoSuccess3(res, file) {
      this.ruleForm3.authorPersonFile = res.data.url;
    },
    handlePhotoSuccess4(res, file) {
      this.ruleForm3.authorCompanyFile = res.data.url;
    },
    handleTaxpayerSuccess(res, file) {
      this.ruleForm3.taxpayerCertificate = res.data.url;
    },
    //申请认证 第三步
    threeForm(formName) {
      var _this = this;
      _this.$refs[formName].validate(valid => {
        if (valid) {
          //       if(_this.ruleForm3.checked) {
          _this.ruleForm3.clientInfoId = _this.ruleForm.clientInfoId;
          updateTClientInfoThree(_this.ruleForm3).then(res => {
            if (res.errno == 0) {
              this.$notify.success({
                title: '成功',
                message: '操作成功'
              });
              this.selectedIndex = 3;
            }
            _this.submitState = {
              state: 'pending',
              message: '认证资料已提交，请联系客服并现场提供盖章的授权委托书原件!'
            };
          });

          // }else {
          //   _this.$message.error('请同意签订认证企业授权承诺书');
          // }
        }
      });
    },
    changeSelectAgreement(val) {
      this.$store.commit('changeBuyerRules', val);
      this.$store.commit('changeElectronicSignature', val);
    },

    toExplainPage(page) {
      if (page === 'buyerRules') {
        this.$router.push({
          path: '/Doc/buyerRules'
        });
      } else {
        this.$router.push({
          path: '/Doc/electronicSignature'
        });
      }
      sessionStorage.setItem('enterpriseInfo', JSON.stringify(this.ruleForm));
    }
  },

  watch: {
    $route: function(newV, oldV) {
      this.selectedIndex = this.$route.query.state || 0;
    }
  }
};
</script>

<style lang="less" scoped>
ul li {
  list-style: none;
}

.mixins_title {
  height: 44px;
  line-height: 44px;
  padding: 0 16px;
  font-weight: bold;
  border-bottom: 2px solid #f6f6f6;
  font-size: 16px;
}

.wrap {
  width: 100%;

  .attestation-box {
    background: #fff;

    .title {
      .mixins_title();
    }
    .container {
      padding: 20px;

      .content {
        overflow: hidden;

        .nav {
          display: flex;
          padding: 0;
          margin: 0;
          width: 100%;

          li {
            position: relative;
            flex: 1;
            height: 34px;
            background: #ececec;
            text-align: center;
            line-height: 34px;
            margin-right: 24px;

            &::before {
              position: absolute;
              top: 0;
              left: -17px;
              width: 0;
              height: 0;
              content: '';
              border-style: solid;
              border-color: #ececec #ececec #ececec transparent;
              border-width: 17px 0 17px 17px;
            }
            &::after {
              position: absolute;
              top: 0;
              right: -17px;
              width: 0;
              height: 0;
              content: '';
              border-style: solid;
              border-color: transparent transparent transparent #ececec;
              border-width: 17px 0 17px 17px;
            }
            &:last-child {
              margin-right: 0px;
            }
            &.selected {
              color: #fff !important;
              background: #409eff;
              // background-image: linear-gradient(to right, #ACB2CA, #33A2C5);
            }
            &.selected::before {
              border-color: #409eff #409eff #409eff transparent;
            }
            &.selected::after {
              border-color: transparent transparent transparent #409eff;
            }
          }
        }
        .main {
          width: 450px;
          margin: 40px auto;

          .el-select,
          .el-input {
            width: 100%;
          }

          .demo-ruleForm1 {
            .upload-license {
              position: relative;

              .license {
                width: 100%;
              }
              .el-upload__customtip {
                position: absolute;
                right: -206px;
                padding-left: 10px;
                color: #a2a2a2;

                &::after {
                  position: absolute;
                  left: 0;
                  top: 0;
                  content: '*';
                  color: #f56c6c;
                }
              }
              .licenseBtn {
                width: 330px;
                height: 40px;
                background: #409eff;
              }
            }
            .nextBtn {
              width: 110px;
              height: 40px;
              // background: #2CA2C6;
            }
          }
          .demo-ruleForm2 {
            .photo-img {
              height: 78px;
              width: 120px;
              border: 1px solid rgba(64, 158, 255, 0.5);
              border-radius: 4px;
            }
            .photo {
              width: 120px;
              height: 76px;
              border: 1px solid rgba(64, 158, 255, 0.5);
              border-radius: 4px;
              display: flex;
              flex-direction: column;
              justify-content: center;

              .el-icon-circle-plus {
                font-size: 28px;
                color: #409eff;
              }
              p {
                line-height: 30px;
              }
            }
            .prevBtn {
              width: 110px;
              height: 40px;
            }
            .submint {
              width: 110px;
              height: 40px;
              // background: #2CA2C6;
            }
          }
          .submit-state {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 350px;
          }
          .agreement-box {
            display: flex;

            .agreement-text {
              flex: 1;
              margin-left: 8px;
              white-space: nowrap;
            }
          }
        }
      }
    }
  }
}
</style>
